import React, { useState } from "react";
import axios from "axios";

function UploadForm() {
    const [file, setFile] = useState(null);
    const [courseType, setCourseType] = useState("krajowy"); // 'zagraniczny' jako alternatywa
    const [routeName, setRouteName] = useState("");
    const [additional, setAdditional] = useState("");

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const logout = (e) => {
        localStorage.removeItem("token");
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("file", file);
        formData.append("name", routeName);
        formData.append("type", courseType);
        formData.append("additional", additional);

        try {
            const response = await axios.post(
                "https://www.bodek1.pl.hostingasp.pl/api/user/routes",
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                }
            );
            console.log(response.data);
            alert("Plik został przesłany!");
        } catch (error) {
            console.error("Wystąpił błąd przy przesyłaniu pliku", error);
            alert("Błąd przesyłania pliku");
        }
    };

    return (
        <form onSubmit={handleSubmit} className="AdminForm">
            <div className="repair">
                <div className="fileInput">
                    <input type="file" onChange={handleFileChange} />
                </div>
                <div className="Select">
                    <select
                        value={courseType}
                        onChange={(e) => setCourseType(e.target.value)}>
                        <option value="krajowy">Kurs Krajowy</option>
                        <option value="zagraniczny">Kurs Zagraniczny</option>
                    </select>
                </div>
                <div className="Text">
                    <input
                        type="text"
                        placeholder="Nazwa trasy"
                        value={routeName}
                        onChange={(e) => setRouteName(e.target.value)}
                    />
                </div>
                <div className="Text">
                    <input
                        type="text"
                        placeholder="Informacje dodatkowe"
                        value={additional}
                        onChange={(e) => setAdditional(e.target.value)}
                    />
                </div>
                <button type="submit">Prześlij</button>
                <button onClick={(e) => logout()}>Wyloguj się</button>
            </div>
        </form>
    );
}
export default UploadForm;
