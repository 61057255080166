import React, { useState, useEffect } from "react";
import main_image from "../img/bus_blue-m.jpg";
import { Link, useNavigate } from "react-router-dom";

import css from "../css/home.css";
const currentYear = new Date().getFullYear();
function test() {
    console.log("test");
}

function Home() {
    const [searchTerm, setSearchTerm] = useState("");
    const [matchedCourses, setMatchedCourses] = useState([]);
    const navigate = useNavigate(); // używane do nawigacji
    const [loading, setLoading] = useState(false);
    const [matchedFiles, setMatchedFiles] = useState([]);
    const files = [
        "Dukla-Miejsce Piastowe-Krosno.xlsx",
        "Krosno-Odrzykoń.xlsx",
        "Odrzykoń-Krosno.xlsx",
        "Krosno-Miejsce Piastowe-Dukla.xlsx",
        "Krosno-Zawadka Rymanowska.xlsx",
        "Zawadka Rymanowska-Krosno.xlsx",
        "Pałacówka-Dukla.xlsx",
        "Dukla-Pałacówka.xlsx",
    ];

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);

        // Filtruj pliki
        if (value.length >= 3) {
            const matched = files.filter((file) =>
                file.toLowerCase().includes(value.toLowerCase())
            );
            setMatchedFiles(matched);
        } else {
            setMatchedFiles([]);
        }
    };
    const handleFileSelect = (fileName) => {
        // Przeniesienie do strony kursów z wybranym plikiem
        navigate("/courses", { state: { selectedFile: fileName } });
    };

    return (
        <section className="main wrapper" id="/">
            <div src={main_image} className="main-img"></div>
            <div className="container">
                <div className="container__img center">
                    <div className="container__img-shadows center">
                        <div className="container__img-text">
                            <h2>Znajdź swoją lokację</h2>
                        </div>

                        <div className="container__img-input">
                            <input
                                type="text"
                                placeholder="Wpisz nazwę miejscowości"
                                onChange={handleSearchChange}></input>

                            {matchedFiles.length > 0 && (
                                <div className="search-results">
                                    {matchedFiles.map((file) => (
                                        <div
                                            key={file}
                                            onClick={() =>
                                                handleFileSelect(file)
                                            }
                                            className="search-results-item">
                                            {file.replace(".xlsx", "")}
                                        </div>
                                    ))}
                                </div>
                            )}
                            <p className="container__img-little-p">lub</p>
                            <Link to="/courses">
                                <button className="container__img-btn">
                                    Przejdź do rozkładów
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="container__info center">
                    <div className="blur"></div>
                    <div className="shadow"></div>
                    <div className="container__info-logo"></div>
                    <hr></hr>
                    <h1 className="container__info-p">
                        Firma Transportowo-Usługowo-Handlowa
                    </h1>
                </div>
            </div>
            <section className="sectionTwo wrapper" id="aboutUs">
                <h2 className="sectionTwo__title">
                    <h2 className="section-title">O nas</h2>
                    <hr></hr>
                </h2>
                <div className="sectionTwo__container">
                    <div className="first">
                        <div className="first__img firstimg"></div>
                        <div className="first__img--shadows"></div>
                        <div className="first__text">
                            <div className="first__text-title">
                                Od jak dawna?
                            </div>
                            <div className="first__text-text">
                                Z dumą możemy pochwalić się ponad 20-letnim
                                doświadczeniem w branży transportowej. To dzięki
                                wieloletniej działalności nasza firma stała się
                                cenionym liderem w dziedzinie przewozów.
                                Wspierani przez wysoko wykwalifikowaną kadrę
                                pracowniczą, jesteśmy w stanie zapewnić naszym
                                klientom solidność oraz komfort podczas każdego
                                zlecenia.
                            </div>
                        </div>
                    </div>
                    <div className="first">
                        <div className="first__img secimg"></div>
                        <div className="first__img--shadows"></div>
                        <div className="first__text">
                            <div className="first__text-title">Bodek</div>
                            <div className="first__text-text">
                                To dynamiczna firma transportowa specjalizująca
                                się w komfortowym i bezpiecznym przewozie osób
                                zarówno na terenie Polski, jak i za granicą.
                                Naszym głównym celem jest zapewnienie wyjątkowej
                                jakości usług, gwarantując klientom nie tylko
                                niezawodny transport, ale także komfort podróży.
                            </div>
                        </div>
                    </div>
                    <div className="first">
                        <div className="first__img lastimg"></div>
                        <div className="first__img--shadows"></div>
                        <div className="first__text">
                            <div className="first__text-title">Wynajem</div>
                            <div className="first__text-text">
                                Oferujemy komfortowe autokary oraz busy dostępne
                                w różnych wariantach z liczbą miejsc od 9 do 59.
                                Cena za wynajem autokaru jest ustalana
                                indywidualnie dla każdego wyjazdu. Nasza firma
                                wystawia faktury VAT, a płatności przyjmujemy
                                zarówno przelewem, jak i gotówką po świadczeniu
                                usługi. Jeśli jesteś zainteresowany, zachęcamy
                                do kontaktu lub przesłania zapytania na adres
                                e-mail info@bodek.pl.
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="sectionThree" id="contact">
                <div className="container">
                    <div className="sectionThree-shadow">
                        <div className="title">
                            <h2>Kontakt</h2>
                            <hr></hr>
                        </div>
                        <div className="sectionThree__contactSection">
                            <div className="sectionThree__contactSection-first">
                                <div className="sectionThree__contactSection-first-title">
                                    <h3>Siedziba firmy</h3>
                                </div>
                                <div className="sectionThree__contactSection-first-text">
                                    <span>Telefon: 602 779 844</span>
                                </div>
                                <div className="sectionThree__contactSection-first-text">
                                    <span>email: info@bodek.pl</span>
                                </div>
                                <div className="sectionThree__contactSection-first-text">
                                    <a
                                        href="https://www.google.com/maps/@49.692518,21.7416499,3a,90y,272.11h,86.37t/data=!3m6!1e1!3m4!1sniKdGqk64wEsac-_bQhicQ!2e0!7i16384!8i8192?entry=ttu"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        Ulica: Michała Mięsowicza 3, 38-400
                                        Krosno
                                    </a>
                                </div>
                            </div>
                            <div className="sectionThree__contactSection-second">
                                <div className="sectionThree__contactSection-second-title">
                                    <h3>Biuro</h3>
                                </div>
                                <div className="sectionThree__contactSection-second-text">
                                    <span>Telefon: 13 420 47 00</span>
                                </div>
                                <div className="sectionThree__contactSection-second-text">
                                    <span>email: info@bodek.pl</span>
                                </div>
                                <div className="sectionThree__contactSection-second-text">
                                    <a
                                        href="https://www.google.com/maps/@49.6957273,21.7520698,3a,67y,349.04h,90.44t/data=!3m6!1e1!3m4!1sbp2WklOU6UEtIHwasFvhHg!2e0!7i16384!8i8192?entry=ttu"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        Ulica: Kolejowa 6, 38-400 Krosno
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer className="footer" id="footer">
                <div className="center">
                    <div className="left">
                        <h3>{currentYear} © BODEK Usługi transportowe</h3>
                    </div>
                    <div className="right">
                        <h3>Wszelkie prawa zastrzeżone</h3>
                    </div>
                </div>
            </footer>
        </section>
    );
}

export default Home;
