import React, { useState, useEffect } from "react";
import axios from "axios";
import "../css/Form.css";
import CoursesList from "./CoursesList";
import UploadForm from "./UploadForm";

function AdminPanel() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            setIsLoggedIn(true);
        }
    }, []);

    const handleLogin = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post(
                //fsa
                "https://www.bodek1.pl.hostingasp.pl/api/account/login",
                {
                    username,
                    password,
                }
            );

            localStorage.setItem("token", response.data.token);
            setIsLoggedIn(true);
        } catch (error) {
            console.error("Błąd logowania", error);
            alert("Błąd logowania");
        }
    };

    if (!isLoggedIn) {
        return (
            <div className="log">
                <form onSubmit={handleLogin} className="AdminForm">
                    <div>
                        <label>
                            Nazwa użytkownika:
                            <input
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </label>
                    </div>
                    <div>
                        <label>
                            Hasło:
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </label>
                    </div>
                    <button type="submit">Zaloguj się</button>
                </form>
            </div>
        );
    }

    return (
        <div className="Form">
            <UploadForm />
            <CoursesList />
        </div>
    );
}

export default AdminPanel;
