import { React, useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../css/curses.css";
import * as XLSX from "xlsx";
import ReactToPrint from "react-to-print";

function Curses() {
    const [data, setData] = useState([]);
    const [title, setTitle] = useState("");
    const location = useLocation();
    const [isExpanded, setIsExpanded] = useState(false); // stan do śledzenia rozwijania listy kursów
    const [comments, setComments] = useState({
        "Dukla-Miejsce Piastowe-Krosno.xlsx": [
            "D - Kursuje od poniedziałku do piątku oprócz świąt, & - dojazd do Szkoły w Turaszówce, S - kursuje w dni nauki sztkolnej.",
            "Respektujemy wszystkie ulgi ustawowe. Telefon: 784 328 223.",
        ],
        "Krosno-Odrzykoń.xlsx": [
            "D - Kursuje od poniedziałku do piątku oprócz świąt, S - kursuje w dni nauki sztkolnej, m - nie kursuje w dniach 24 i 31.XII",
            "Respektujemy wszystkie ulgi ustawowe. Telefon: 784 328 223.",
        ],
        "Odrzykoń-Krosno.xlsx": [
            "D - Kursuje od poniedziałku do piątku oprócz świąt, S - kursuje w dni nauki sztkolnej, m - nie kursuje w dniach 24 i 31.XII",
            "Respektujemy wszystkie ulgi ustawowe. Telefon: 784 328 223.",
        ],
        "Krosno-Miejsce Piastowe-Dukla.xlsx": [
            "D - Kursuje od poniedziałku do piątku oprócz świąt, # - kursuje do Zawadki Rymanowskiej, S - kursuje w dni nauki sztkolnej.",
            "Respektujemy wszystkie ulgi ustawowe. Telefon: 784 328 223.",
        ],
        "Pałacówka-Dukla.xlsx": [
            "D - Kursuje TYLKO w czwartki",
            "Respektujemy wszystkie ulgi ustawowe. Telefon: 784 328 223.",
        ],
        "Krosno-Zawadka Rymanowska.xlsx": [
            "D - Kursuje od poniedziałku do piątku oprócz świąt, S - kursuje w dni nauki sztkolnej.",
            "Respektujemy wszystkie ulgi ustawowe. Telefon: 784 328 223.",
        ],
        "Zawadka Rymanowska-Krosno.xlsx": [
            "D - Kursuje od poniedziałku do piątku oprócz świąt, S - kursuje w dni nauki sztkolnej.",
            "Respektujemy wszystkie ulgi ustawowe. Telefon: 784 328 223.",
        ],
        "Dukla-Pałacówka.xlsx": [
            "D - Kursuje TYLKO w czwartki",
            "Respektujemy wszystkie ulgi ustawowe. Telefon: 784 328 223.",
        ],
        // "Krosno-Rzepnik.xlsx": [
        //     "D - Kursuje od poniedziałku do piątku oprócz świąt",
        //     "Respektujemy wszystkie ulgi ustawowe. Telefon: 784 328 223.",
        // ],
        // "Rzepnik-Krosno.xlsx": [
        //     "D - Kursuje od poniedziałku do piątku oprócz świąt",
        //     "Respektujemy wszystkie ulgi ustawowe. Telefon: 784 328 223.",
        // ],
        // "Rzepnik-Odrzykoń.xlsx": [
        //     "D - Kursuje od poniedziałku do piątku oprócz świąt",
        //     "Respektujemy wszystkie ulgi ustawowe. Telefon: 784 328 223.",
        // ],
        // "Odrzykoń-Rzepnik.xlsx": [
        //     "D - Kursuje od poniedziałku do piątku oprócz świąt",
        //     "Respektujemy wszystkie ulgi ustawowe. Telefon: 784 328 223.",
        // ],
    });
    const componentRef = useRef();
    const [courses, setCourses] = useState([
        "Odrzykoń-Krosno.xlsx",
        "Krosno-Odrzykoń.xlsx",
        "Krosno-Miejsce Piastowe-Dukla.xlsx",
        "Dukla-Miejsce Piastowe-Krosno.xlsx",
        "Krosno-Zawadka Rymanowska.xlsx",
        "Zawadka Rymanowska-Krosno.xlsx",
        "Dukla-Pałacówka.xlsx",
        "Pałacówka-Dukla.xlsx",
        // "Krosno-Rzepnik.xlsx",
        // "Rzepnik-Krosno.xlsx",
        // "Rzepnik-Odrzykoń.xlsx",
        // "Odrzykoń-Rzepnik.xlsx",
    ]); // Statycznie zdefiniowane nazwy plików

    const toggleCoursesList = () => {
        setIsExpanded(!isExpanded); // przełącz stan rozwinięcia listy
    };

    const loadExcelData = async (fileName) => {
        const filePath = `./files/${fileName}`; // Zakładamy, że pliki są w folderze public/files
        fetch(filePath)
            .then((response) => response.arrayBuffer())
            .then((buffer) => {
                const workbook = XLSX.read(buffer, { type: "buffer" });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const parsedData = XLSX.utils.sheet_to_json(sheet);
                setData(parsedData);
                setTitle(fileName.replace(".xlsx", ""));
            })
            .catch((err) => console.error("Error loading file", err));
    };
    const formatValue = (value) => {
        if (typeof value === "string" && value.includes(",")) {
            return value.replace(",", ":");
        }
        return value;
    };
    const cleanColumnName = (columnName) => {
        return columnName.replace(/_\d+$/, "");
    };

    useEffect(() => {
        // Check if there's a file selected from navigation state
        if (location.state && location.state.selectedFile) {
            loadExcelData(location.state.selectedFile);
        }
    }, [location.state]); // React to changes in location.state

    return (
        <section id="curses" className="curses">
            <title>BODEK - Rozkład jazdy</title>
            <meta
                name="description"
                content="Sprawdź rozkład jazdy BODEK. Planuj swoje podróże z nami."
            />
            <div className={`curses__lists ${isExpanded ? "showList" : ""}`}>
                {courses.map((course, index) => (
                    <button
                        key={index}
                        className="btn curses__lists--CursesBtn"
                        onClick={() => loadExcelData(course)}>
                        {course.replace(".xlsx", "")}
                    </button>
                ))}
            </div>
            <div
                id="curses__table"
                className={`curses__table ${isExpanded ? "showTable" : ""}`}
                ref={componentRef}>
                <button
                    className={`curses__table-showBtn ${
                        isExpanded ? "rotateBtn" : ""
                    }`}
                    onClick={toggleCoursesList}></button>
                <div className="curses__table-title">{title}</div>
                {data.length > 0 && (
                    <table className="curses__table-main-table">
                        <thead>
                            <tr>
                                {Object.keys(data[0]).map((key) => (
                                    <th key={key}>{cleanColumnName(key)}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((row, index) => (
                                <tr key={index}>
                                    {Object.values(row).map((value, index) => (
                                        <td key={index}>
                                            {formatValue(value)}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
                {title && (
                    <div className="curses__table-main-table-time">
                        Aktualizacja: 09/05/2024
                    </div>
                )}
                {title &&
                    comments[title + ".xlsx"] &&
                    comments[title + ".xlsx"].map((comment, index) => (
                        <div key={index} className="curses__comment">
                            {comment}
                        </div>
                    ))}
                {/* Display the comment for the loaded file */}
            </div>
            <ReactToPrint
                trigger={() => <button className="print"></button>}
                content={() => componentRef.current}
            />
        </section>
    );
}

export default Curses;
