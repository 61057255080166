import { React, useState } from "react";
import {
    BrowserRouter as Router,
    Route,
    Link,
    Routes,
    useLocation,
} from "react-router-dom";

//ROUTES
import Home from "./Components/Home";
import About from "./Components/Curses";
import Curses from "./Components/Curses";
import Admin from "./Components/AdminPanel";
import CoursesList from "./Components/CoursesList";
import UploadForm from "./Components/UploadForm";

//CSS
import css from "./css/nav.css";
import { useEffect } from "react";

function App() {
    const [BurgerActive, setActiveBurger] = useState(true);

    const BurgerClick = () => {
        setActiveBurger(!BurgerActive);
    };
    const BurgerOff = () => {
        setActiveBurger(false);
    };
    const up = () => {
        window.scrollTo(0, 0);
        BurgerClick();
    };

    return (
        <Router>
            <nav className="nav-section" /*onScroll={handleScroll}*/>
                <button onClick={BurgerClick} className={`btn-nav tests`}>
                    <div className="burgerBtn"></div>
                </button>
                <nav className="navigation">
                    <div
                        className={`nav__mobile ${
                            BurgerActive ? "active" : ""
                        }`}>
                        <ul className={`nav__mobile--list`}>
                            <li>
                                <Link
                                    className="nav__mobile-link"
                                    to="/"
                                    onClick={up}>
                                    <span>Strona główna</span>
                                </Link>
                            </li>
                            <li>
                                <a
                                    className="nav__mobile-link"
                                    href="#aboutUs"
                                    onClick={BurgerClick}>
                                    <span>O nas</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    className="nav__mobile-link"
                                    href="#contact"
                                    onClick={BurgerClick}>
                                    <span>Kontakt</span>
                                </a>
                            </li>
                            <li>
                                <Link
                                    className="nav__mobile-link"
                                    to="/courses"
                                    onClick={BurgerClick}>
                                    <span>Kursy</span>
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <ul className="nav nav-list">
                        <li>
                            <Link
                                className="nav__link nav__link--logo"
                                to="/"
                                onClick={up}>
                                <span>Bodek</span>
                            </Link>
                        </li>
                        <div className="other-links">
                            <li>
                                <a className="nav__link" href="/#aboutUs">
                                    <span>O nas</span>
                                </a>
                            </li>
                            <li>
                                <a className="nav__link" href="/#contact">
                                    <span>Kontakt</span>
                                </a>
                            </li>
                            <li>
                                <Link className="nav__link" to="/courses">
                                    <span>Kursy</span>
                                </Link>
                            </li>
                        </div>
                    </ul>
                    <div
                        className={`anim ${
                            BurgerActive ? "" : "backgrnd"
                        }`}></div>
                </nav>
                <Routes>
                    <Route path="/" exact element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/courses" element={<Curses />} />
                    <Route path="/admin" element={<Admin />} />
                    <Route path="/*" element={<Home />} />
                </Routes>
            </nav>
        </Router>
    );
}

export default App;
