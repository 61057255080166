import React, { useState, useEffect } from 'react';
import axios from 'axios';

function CoursesList() {
    const [courses, setCourses] = useState([]);

    useEffect(() => {
      const fetchCourses = async () => {
        try {
          const response = await axios.get('https://www.bodek1.pl.hostingasp.pl/api/user/routes', {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem("token")}`
            }
          });
          setCourses(response.data);
        } catch (error) {
          console.error('Wystąpił błąd podczas pobierania kursów', error);
        }
      };

      fetchCourses();
    }, []);

    const deleteCourse = async (courseId) => {
      try {
        await axios.delete(`https://www.bodek1.pl.hostingasp.pl/api/user/routes/${courseId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem("token")}`
          }
        });
        setCourses(courses.filter(course => course.id !== courseId));
      } catch (error) {
        console.error('Wystąpił błąd podczas usuwania kursu', error);
      }
    };

    return (
      <div className='delForm'>
        {courses.map(course => (
          <div key={course.id}>
            <span>{course.name} </span>
            <button onClick={() => deleteCourse(course.id)}>Usuń</button>
          </div>
        ))}
      </div>
    );
}

export default CoursesList;
